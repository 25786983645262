<template>
  <v-stepper>
    <template #1="{ changeActiveStep }">
      <send-phone @submitted="submitted($event, changeActiveStep)" />
    </template>
    <template #2>
      <send-code :phone="phone" />
    </template>
  </v-stepper>
</template>

<script>
import VStepper from '@/components/common/VStepper.vue'
import SendCode from '@/components/LoginOwner/SendCode.vue'
import SendPhone from '@/components/LoginOwner/SendPhone.vue'

export default {
  name: 'LoginOwner',
  components: {
    SendCode,
    SendPhone,
    VStepper
  },
  data() {
    return {
      phone: ''
    }
  },

  methods: {
    submitted(phone, changeActiveStep) {
      this.phone = phone
      changeActiveStep(2)
    }
  }
}
</script>
