<template>
  <div class="input-code-list">
    <v-input
      ref="input_0"
      type="text"
      size="small"
      maxlength="1"
      inputmode="numeric"
      v-bind="$attrs"
      :value="localValue[0]"
      :filled="!!localValue[0]"
      @keydown="handleKeydown($event, 0)"
      @paste="handlePaste"
    />
    <v-input
      ref="input_1"
      type="text"
      size="small"
      v-bind="$attrs"
      maxlength="1"
      inputmode="numeric"
      :value="localValue[1]"
      :filled="!!localValue[1]"
      @keydown="handleKeydown($event, 1)"
      @paste="handlePaste"
    />
    <v-input
      ref="input_2"
      type="text"
      size="small"
      inputmode="numeric"
      v-bind="$attrs"
      maxlength="1"
      :value="localValue[2]"
      :filled="!!localValue[2]"
      @keydown="handleKeydown($event, 2)"
      @paste="handlePaste"
    />
    <v-input
      ref="input_3"
      type="text"
      size="small"
      v-bind="$attrs"
      maxlength="1"
      inputmode="numeric"
      :value="localValue[3]"
      :filled="!!localValue[3]"
      @keydown="handleKeydown($event, 3)"
      @paste="handlePaste"
    />
  </div>
</template>

<script>
import VInput from '@/components/common/VInput.vue'
import { BACKSPACE_CODE, TAB_CODE, ARROW_LEFT_CODE, ARROW_RIGHT_CODE, KEY_CODES_NUMBER } from '@/constants/keyCodes'

export default {
  name: 'VInputCode',
  components: {
    VInput
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: []
    }
  },
  computed: {
    inheritListeners() {
      const { input: _input, ...restListeners } = this.$listeners
      return restListeners
    }
  },
  mounted() {
    this.setLocalValueByString(this.value)
  },
  methods: {
    handlePaste(event) {
      const clipData = event.clipboardData.getData('text')

      if (clipData.length === 4 && Number(clipData) >= 1000) {
        this.setLocalValueByString(clipData)
        event.preventDefault()
      }
    },

    handleKeydown(event, index) {
      const { key } = event
      if (KEY_CODES_NUMBER.includes(key)) {
        this.localValue[index] = key
        this.focusByIndex(index + 1)
      } else if (key === BACKSPACE_CODE) {
        this.localValue[index] = null
        this.focusByIndex(index - 1)
      }
      if (key === ARROW_LEFT_CODE) this.focusByIndex(index - 1)
      if (key === ARROW_RIGHT_CODE) this.focusByIndex(index + 1)

      if (key !== TAB_CODE) {
        if (key !== 'v' || !(event.ctrlKey || event.metaKey)) {
          event.preventDefault()
        }
        event.stopPropagation()
      }

      this.sendInput()
    },

    focusByIndex(index) {
      const input = this.$refs[`input_${index}`]
      if (input) {
        input.focus()
      }
    },

    setLocalValueByString(str) {
      const arrValue = str.split('')
      arrValue.length = 4
      this.localValue = arrValue

      this.sendInput()
      this.focusByIndex(str.length - 1)
    },

    sendInput() {
      this.$emit('input', this.localValue.join(''))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/common/input';

.input-code-list {
  display: flex;
  justify-content: space-between;
}
</style>
